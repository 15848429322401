<template>
  <div class="Awarp">
    <dashCard class="lvscBox10" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption"
              @changeScreen="changeScreen" style="overflow-x: scroll;
    overflow-y: scroll;">
      <template slot="title">{{commonLangHandler('lvscBox212_singleLine','单线图', getZEdata)}}</template>
      <div style="display: flex;justify-content: center;">
        <div style="width: 1100px;height: 558px;position: relative;text-align: center;overflow-y: auto;overflow-x: auto;">

          <img id="canvas_img2" style="width:100%;"  src="">
          <img v-for="(item,index) in clicklist" @mouseenter="dianenter(item)" @mouseleave="dianout(item)"    :key="index" class="dian" :src="item.status =='合闸'?red:green" :style="{top:item.y+'px',left:item.x+'px'}">
          <div class="tan" :style="{top:tan.y+'px',left:tan.x+'px'}" v-if="showtan">
            <div style="    margin-left: 10px;padding-bottom: 5px;font-size: 16px;font-weight: 600;text-align: left;border-bottom: 1px solid #c7c7c7;">{{eq.machineName}}</div>
            <div class="group">

              <div style="margin-right: 15px;    width: 33px;font-size: 14px;    text-align: left;">Ib:</div>
              <div>{{eq.realValue.lb}} (A)</div>
            </div>
            <div class="group">

              <div style="margin-right: 15px;    width: 33px;font-size: 14px;    text-align: left;">Uab:</div>
              <div>{{eq.realValue.Uab}} (V)</div>
            </div>
            <div class="group">

              <div style="margin-right: 15px;    width: 33px;font-size: 14px;    text-align: left;">P:</div>
              <div>{{eq.realValue.P}} (kW)</div>
            </div>
          </div>
        </div>
      </div>
    </dashCard>
    <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false"
             width="100%" :dialog-style="{ top: '0px' }">
      <div style="height:calc(100vh)">
        <lvscBox212 :option="option" :isModal="true" :orginSearchObj="searchObj"/>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Img001 from "@/assets/imgs/add/product1.png"

export default {
  name: 'lvscBox21',
  components: {
    dashCard: () => import('@/component/dashCard.vue'),
    chartBoard: () => import('@/component/chartBoard.vue'),
    lvscBox212: () => import('@/component/chartStore/charFiles/lvscabinetCom/lvscBox212.vue'),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return {w: 6, h: 6}
      }
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false
        }
      }
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick(_ => {
          this.getOperateWidth()
        })
      },
      deep: true,
      immediate: true
    },
    "option.visible": {//全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj
            this.getData()
          }
        }
      },
      deep: true,
      immediate: true
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getList();
          this.getimglist()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      eq:{
        realValue: {
            P: "0",
            lb: "0",
            Uab:"0"
        }
      },
      tan:{
        x:'',
        y:''
      },
      green:require("@/assets/imgs/green.png"),
      red:require("@/assets/imgs/red.png"),
      showtan:false,
      operateWidth: 64, //操作列宽度
      isLoading: false,//数据加载中
      clicklist:[],
      itemList: [
        // {
        //   code: '1AT1_抽屉柜',
        //   name: '1AT1_抽屉柜'
        // },
        // {
        //   code: '1AT2_抽屉柜',
        //   name: '1AT2_抽屉柜'
        // },
        // {
        //   code: '1AT3_抽屉柜',
        //   name: '1AT3_抽屉柜'
        // }
      ],

      columns: [
        {title: '型号', value1: 'value1'},
        {title: '上次维护时间', value1: 'value2', value2: 'value3'},
        {title: '额定电压', value1: 'value4', value2: 'value5'},
        {title: '额定电流', value1: 'value6', value2: 'value7',},
        {title: '额定频率', value1: 'value8', value2: 'value9'},
      ],
      searchObj: {
        ITEM: '',//选中的项目
      },
      detailInfo: {},
    }
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo
    }
  },
  methods: {
    
    getimglist(){
      this.$axios({
        url: Config.dev_url + "/api-apps/app/FLAGIMAGE/list",
        method: "post",
        data: {
          limit: 20,
          p_order: "[]",
          p_query: "{\"links\":[{\"key\":\"DCODE\",\"links\":[],\"type\":\"Like\",\"value\":\""+this.currentSelectDeptInfo.CODE+"\"}],\"type\":\"LinkAnd\"}",
          page: 1
        }
      }).then(res => {
        let arr = []
        for(let item of res.data.data){

          let obj = JSON.parse(JSON.stringify(JSON.parse(item.FLAGIMAGE)))
          if(obj.imgstatus =='激活'){
            this.clicklist = obj.clicklist
            for(let index in this.clicklist){
              this.$axios({
                url: Config.dev_url + "/api-apps-v2/api/v2/equipment/variable/value",
                method: "post",
                data: {
                  assetNumber: this.clicklist[index].asset,
                }
              }).then(res => {
                let a = JSON.parse(JSON.stringify(this.clicklist[index]))
                a.status = res.data.data.status
                this.$set(this.clicklist,index,a)

              })
            }
            //console.log(this.clicklist)
            //console.log("--------------")
            this.$axios({
              url: Config.dev_url + "/api-file/file/download?fid="+obj.imgid,
              method: "get",
              responseType: "blob",
            }).then(res => {

              let canvas_img = document.querySelector('#canvas_img2');
              //console.log(res)
              const blob = new Blob([res.data], { type: 'image/png' })
              const imageUrl = (window.URL || window.webkitURL).createObjectURL(blob)
              canvas_img.src = imageUrl;
              // canvas_img.onload = function(e) {
              //     window.URL.revokeObjectURL(canvas_img.src);
              // };

              // this.getBase64(res.data).then((base64) => {

              //     this.imgsrc = base64
              //     //console.log('base64', this.imgsrc)
              //   })
            })
          }
        }
        console

      })
    },
    dianout(){
      this.showtan = false
    },
    dianenter(item){
      this.$nextTick(function () {
        let vm = this
        setTimeout(() => {
          console.log(vm.tan.x)
          if(item.x+160+300>1100){
            vm.$set(vm.tan,"x",item.x-190)
          }else{
            vm.$set(vm.tan,"x",item.x+50)
          }
          console.log(vm.tan.x)
          vm.$set(vm.tan,"y",item.y)

          vm.$axios({
            url: Config.dev_url + "/api-apps-v2/api/v2/equipment/variable/value",
            method: "post",
            data: {
              assetNumber: item.asset,
            }
          }).then(res => {
            vm.eq = res.data.data
          })
          vm.showtan = true
        }, 100);
     
      
      })
      
    },
    clickimg(event){

      let obj = {
        x:event.offsetX-30,
        y:event.offsetY-30
      }
      this.clicklist.push(obj)
    },
    getList() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method: "post",
        data: {
          deptCode: this.currentSelectDeptInfo.CODE,
          type:"电表"
        }
      }).then(res => {
        if(!res.data.data){
          this.itemList = []
        }else{
          this.itemList = res.data.data
          if (this.itemList.length !== 0) {
            this.searchObj.ITEM = this.itemList[0].assetNumber
          }
        }
        
        this.getData()
      })
    },
    geteq() {

    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal
      if (!this.option.visible) {
        this.getData()
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return
      }
      var dom = this.$el.querySelector(".card-head-operate")
      if (!!dom) {
        var width = dom.clientWidth
        this.operateWidth = width > 0 ? width + 12 : width
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0
      // 获取整数部分
      const intPart = Math.trunc(value)
      // 整数部分处理，增加,
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      // 预定义小数部分
      let floatPart = ''
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    },

    // 切换项目
    handleChange(value) {
      this.getData()
    },
    // 获取数据
    getData() {
      // this.$axios({
      //   url: Config.dev_url + "/api-apps/lowVoltage/realtimeValue",
      //   method: "post",
      //   data: {
      //     assetNumber: this.searchObj.ITEM,
      //   }
      // }).then(res => {
      //   this.isLoading = true
      //   let result = res.data.data
      //   if(result.length === 0){
      //     this.detailInfo = {
      //       productSrc: Img001,
      //       status: "",
      //     }
      //     this.detailInfo["list"] = result
      //     this.isLoading = false
      //     return
      //   }
      //   let index = result.findIndex(item => {
      //     return item.name === "工作状态"
      //   })
      //   this.detailInfo = {
      //     productSrc: Img001,
      //     status: result[index].num
      //   }
      //   result.splice(index,1)
      //   this.detailInfo["list"] = result
      //   //console.log(this.detailInfo)
      //   this.isLoading = false
      //   //console.log(res)
      // })

    },
    getData0() {
      this.isLoading = true
      this.detailInfo = {
        value1: "ABB_3P 63A-400A",
        value2: "128",
        value3: "h",
        value4: "400",
        value5: "V",
        value6: "1000",
        value7: "A",
        value8: "2780",
        value9: "kWh",


        value10: "380V",
        value11: "200kW",
        value12: "80℃",
        productSrc: Img001
      }
      this.isLoading = false
    }
  },
  mounted() {


    // let canvas_img = document.querySelector('#canvas_img2');
    // canvas_img.src = "";
    this.getOperateWidth()
    if (!this.option.visible) {
      this.getList()

      this.getimglist()
      // this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.lvscBox10 {
  .card-content1 {
    display: flex;

    .product-pre {
      width: 362px;
      height: 336px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .product-info {
      flex: 1;

      .product-status {
        div {
          &:nth-child(1) {
            font-size: 16px;
            font-weight: 400;
            color: #595959;
            line-height: 24px;
          }

          &:nth-child(2) {
            margin-top: 6px;
            margin-bottom: 16px;

            font-size: 36px;
            font-weight: 500;
            color: #3366ff;
            line-height: 48px;
          }
        }
      }

      .product-info-item {
        margin-bottom: 24px;

        .product-info-item-top {
          display: flex;
          justify-content: space-between;

          font-size: 16px;
          font-weight: 500;
          color: #1f1f1f;
          line-height: 20px;
        }

        .product-info-item-progress {
          width: 100%;
          height: 8px;
          background: #dbdbdb;
          margin-top: 14px;
          margin-bottom: 9px;

          span {
            display: block;
            height: inherit;
            width: 0%;
            background: #51A34B;
          }
        }

        .product-info-item-bottom {
          display: flex;
          justify-content: space-between;

          font-size: 14px;
          font-weight: 400;
          color: #696969;
          line-height: 16px;
        }
      }

    }

  }

  /deep/ .ant-descriptions-bordered {
    .ant-descriptions-item-label {
      background: transparent;
      width: 362px;
    }

    .ant-descriptions-item-content {
      background: #F5F5F5;
      display: flex;
      justify-content: space-between;
    }
  }
}

.dian{
  position: absolute;
  width: 30px;
}
.tan{
  background: white;
  border-radius: 9px;
  position: absolute;
  width: 160px;
  height: 150px;
  background: rgba(255, 255, 255,1);
  color:#454545;
  padding:10px;
  border: 1px solid rgb(121, 119, 119);
}

.group{
  display: flex;
  margin-top: 15px;
  margin-left:10px;
  align-items: center;
  font-size: 12px;
}
</style>
